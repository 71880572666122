import gql from "graphql-tag";
import { commercialUnitFragment } from "./CommercialUnit";
import { batchFragment } from "./Batch";
import { packingKindFragment } from "./PackingKind";
export const productUnitSimpleFragment = gql`
  fragment ProductUnitSimple on ProductUnit  {
    id,
    name,
    productId,
    commercialUnitId,
    parentId,
    barcode,
    multiplier,
    grossWeight,
    netWeight,
    height,
    width,
    length,
    discardedAt,
    packingKindId,
    productionTracking,
    batchTracking,
    minimum,
    maximum,
    commercialUnit {
      ...CommercialUnit
    }
    batches {
      ...Batch
    }
    packingKind {
      ...PackingKind
    }    
  }
  ${commercialUnitFragment}
  ${batchFragment}
  ${packingKindFragment}
`;